:root {
  --app-header-height: 60px;
}

.App {}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (max-width: 990px) {
  .justify-center-on-small {
    justify-content: center;
  }

  .text-align-center-on-small {
    text-align: center;
  }
}


.App-header {
  background-color: #282c34;
  min-height: fit-content;
  /* min-height: var(--app-header-height); */
  /* max-height: var(--app-header-height); */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px 5px 15px;
  /* position: fixed;
  top:0;
  width:101%;
  z-index: 1000; */
  /* font-size: calc(10px + 2vmin); */

  color: white;
}

.z-index-1000 {
  z-index: 1000;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

a {
  color: inherit;
  text-decoration: none;
}

a.player-name-link:hover {
  color: blue;
  text-decoration: underline;
}

a.discord-link {
  color: blue;
  text-decoration: underline;
  font-weight: 600;
}

a:hover {
  color: inherit;
}

.matchup-details.clickable {
  color: blue;
}

h4.clickable {
  cursor: pointer;
}

span.clickable:hover {
  cursor: pointer;
  color: blue;
}

span.park-factor:hover {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

span.park-factor {
  color: blue;

  cursor: pointer;
}

.inline-block {
  display: inline-block;
}

.float-right {
  float: right;
}

.game-tile {
  border-right: 1px solid #CCC;
  border-bottom: 2px solid black;
  border-top: 2px solid black;
  margin-top: 5px;
  margin-bottom: 5px;
  min-width: 350px;
  max-width: 400px;
  text-align: center;
  padding-bottom: 5px;

}



.game-tile-img {
  margin: 10px;
  max-width: 300px;
  min-width: 300px;
  text-align: center;
}

.team-info {
  background-color: rgb(243, 243, 243);
  padding: 1px;
}


.team-abbr {
  display: inline-block;
  font-weight: bolder;
}

.bolder {
  font-weight: bolder;
}

.bold {
  font-weight: bold;
}

.team-stat {
  display: inline-block;
  font-size: small;
}

.park-factor {
  font-size: x-small;
  text-align: right;
}

.player-name-link {
  font-size: medium;

  font-weight: 550;
}

.pitcher-stats {
  font-size: x-small;

}

.logged-in-email {
  max-width: fit-content;
  min-width: fit-content;
  display: inline-flex;
  float: left;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-top-5 {
  margin-top: 5px;
}

.col.yahoo-auth {
  max-width: fit-content;
  min-width: fit-content;
  display: flex;
}

.row.yahoo-auth {
  justify-content: end;
  margin: 3px;
}

.game-row {
  border-style: solid;
  border-color: lightgray;
  border-width: 1px;
  margin: 5px;
}

.game-date {
  font-size: large;
  margin: 5px
}

.error {
  color: red;
}

.add-drop-error {
  background-color: #f8d7da;
  border-color: #f5c2c7;
  border: 1px solid transparent;
  border-radius: .25rem;
}

.loading-indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
}

.loading-indicator.relative {
  position: relative;
}

.welcome {


  padding: 15px;

}

.registration-form,
.login-form {
  width: fit-content;
  padding: 5px;
  border-style: solid;
  border-color: black;
  border-width: 3px;
  border-radius: 5%;
  background-color: white;
  color: black;
  display: inline-block;
  margin-top: 10px;
  box-shadow: -5px 5px #282c34;
}

/* .register-form-container {
  width: 100%;
} */

.app-preview-image {
  width: 300px;
}

.league-id-container {
  text-align: center;
}

.league-id-img {
  width: 340px;
}

.url-display {
  background-color: #ededed;
  padding: 2px;
  font-size: smaller;
}

.league-id-form {
  min-width: fit-content;
  text-align: center;
}

::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder {
  text-align: center;
}

.connect-league-table-container {
  justify-items: center;
}

.connected-leagues-container {
  text-align: center;
  margin: 5px
}

.connected-leagues-table {
  vertical-align: middle;
  max-width: 750px;
}

.connected-leagues-header {
  margin-top: 5px
}

.drawer-hide-button {
  margin-left: 375px;
  margin-top: -15px;

}

span.bold {
  font-weight: 550;
}

tr.bold {
  font-weight: 550;
}

.hitting-team-logo {
  height: 25px;
}

.player-info-headshot {
  width: auto;
  height: 90%;
}

.weather-team-logo {
  height: 50px;
  margin-left: 5px;
  margin-right: 5px;
}


.fit-better {
  /* margin:5px;  */
  padding: 10px;
  
}

.bg-light-gray {
  background-color: rgb(243, 243, 243);
}

.bg-white {
  background-color: white;
}

.team-record {
  font-size: 70%;
  padding-top: 3px;
}

.hidden {
  display: none;
}

button.show-drawer {
  position: absolute;
  top: 95px;
  min-width: fit-content
}

.min-width-100 {
  min-width: 100px;
}

.justify-items-center {
  justify-items: center;
}

.justify-items-left {
  justify-items: left;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}


.text-align-left {
  text-align: left;
}

.justify-center {
  justify-content: center;
}

.justify-right {
  justify-content: right;
}

.border-none {
  border: none;
}

.yahoo-authentication-failure {
  margin: 20px;
  max-width: fit-content;
  min-width: fit-content;
}

.error-message {
  text-align: center;
}

.fit-content {
  min-height: fit-content;
  max-height: fit-content;
  min-width: fit-content;
  max-width: fit-content;
}

.landing-page {
  padding: 10px;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
}


.landing-page-content {
  position: relative;
  font-family: Georgia, 'Times New Roman', Times, serif;
  height: 100vh;
  align-content: center;
  align-items: center;
}

.login-form-label {
  text-align: left;
  color: gray;
}

.login-form-submit {
  margin-top: 2px;
  ;
}

.white-text {
  color: white;
}

.primary {
  color: #0d6efd;
}

.app-image-container {}

.app-sample-img {
  width: 100%;
  z-index: 3;
  position: relative;
}

.app-phone-img {
  width: 20%
}

.show-free-agent-instructions {
  margin-top: 50px
}

.centered-on-screen {

  position: fixed;
  top: 50%;
  left: 47%;
  transform: translate(-50%, -50%);
  max-width: 95%;

}

.table-constant {
  background-color: rgb(240, 240, 240);
}

.game-time-hours {
  background-color: lightcyan !important;
}

.weather-game-header {
  background-color: rgb(240, 240, 240) !important;
  padding: 5px;
}

.statcast {
  background-color: aliceblue !important;
}

.faded-icon {
  opacity: 40%;
}


.grayed-out {
  color: gray;
}

.solid-border {
  border-style: solid;
  border-color: black;
  border-width: 1px;
}

/* .solid-border-success {
  border-style: solid;
  border-color: green;
  border-width: 1px;
}

.solid-border-warn {
  border-style: solid;
  border-color: red;
  border-width: 1px;
}

.add-drop-name {
  max-width: fit-content;
  min-width: fit-content;
} */

.pitcher-hitter-matchup-container {
  margin-top: 10px;
  position: relative;
  min-height: 150px;
}

.watchlist-pitcher-info {
  margin-top: 20px;
}

.margin-5 {
  margin: 5px;
}

.hitter-zone {
  border-style: solid;
  border-color: black;
  border-width: 1px;

  min-height: 75px;
}

.add-drop-stage {
  padding: 15px;
  margin: 15px;
}

.pitch-circle {
  width: 20px;
  height: 20px;
  /* background-color: red; */
  border-radius: 50%;
  position: absolute;
  display: inline-block;
}

.height-50 {
  min-height: 50px;
}

.strike-zone {
  position: relative;
  min-height: 200px;
}

.pitcher-info {
  border-width: 1px;
  border-style: solid;
  border-color: #282c34;
  padding: 15px
}

p.danger {
  /* color: #842029; */
  background-color: #f8d7da;
  border-color: #f5c2c7;
  border: 1px solid transparent;
  border-radius: .25rem;
}


.player-ranking-value {
  justify-content: center;
  font-weight: bolder;
  font-size: 110%;
}

.player-ranking-value .warn {
  /* color: #ffc107; */
  color: #dc3545;
  width: fit-content;
}


.player-ranking-value.sm.de-emphasized {
  font-size: 70%;
  font-weight: 500;
}

.player-ranking-label {
  justify-content: center;
  font-size: 70%
}

.player-ranking-value.sm {
  justify-content: center;
  font-weight: bold;
  font-size: 90%;
}

.player-ranking-label.sm {
  justify-content: center;
  font-size: 60%
}

.player-ranking-value.xs {
  justify-content: center;
  font-weight: bold;
  font-size: 70%;
}

.player-ranking-label.xs {
  justify-content: center;
  font-size: 60%
}

.text-size-60 {
  font-size: 60%;
}

.text-size-70 {
  font-size: 70%;
}

.text-size-75 {
  font-size: 75%;
}

.text-size-80 {
  font-size: 80%;
}

.text-size-90 {
  font-size: 90%;
}

.text-size-95 {
  font-size: 95%;
}

.text-size-110 {
  font-size: 110%;
}

.margin-10 {
  margin-bottom: 10px;
}

.padding-5 {
  padding: 5px;
}

.red {
  color: rgb(225, 0, 0);
}

.green {
  color: rgb(0, 200, 0)
}

.display-flex {
  display: flex;
}

.hitter-finder-container {
  max-width: 1400px;
  /* border-style: solid;
  border-color: black;
  border-width: 1px; */
  width: 100%;
}

.hitter-finder-hitter-info {
  margin: 5px;
  /* overflow:hidden; */
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  /* border-style: solid; */
  /* width:100%;

  @media (min-width: 768px) {
    width: calc(100vw/3) 
  } */

}

.hitter-finder-break {
  min-height: 15px;
}

.tiny-week-row {
  margin-left: auto;
  overflow-x: auto;
}

.tiny-week-box {
  border-right: 1px solid #dddddd;
  min-width: 45px;
}

.tiny-week-box:last-child {
  border-right: none;
}

.bottom-border {
  border-bottom: 1px solid #dddddd;
}

.bottom-top {
  border-top: 1px solid #dddddd;
}



.player-info {
  min-width: 75%;
}

.player-info .name {
  margin-left: -25px;
  text-align: left;
}

.filter-dropdown {
  width: 100%;
}

.apply-filter-button-row {
  margin-top: 2px;
}

.view-selector.active {
  font-weight: bold;
  border-bottom: 2px solid #0d6efd;
}

.view-selector.active.expandable {
  font-weight: bold;
  border-bottom: none;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
}


.player-stat-row-label {
  align-content: center;
  justify-content: right;

  /* font-weight: bold; */
  font-size: 80%;
}



.green-gradient {
  background: radial-gradient(circle, rgba(0, 255, 0, .5) 0%, rgba(0, 255, 0, 0) 40%);
}

.red-gradient {
  background: radial-gradient(circle, rgba(255, 0, 0, .5) 0%, rgba(255, 0, 0, 0) 40%);
}

.recent-stats.green-gradient {
  background: radial-gradient(circle, rgba(0, 255, 0, .6) 0%, rgba(0, 255, 0, 0) 55%);
}

.recent-stats.red-gradient {
  background: radial-gradient(circle, rgba(255, 0, 0, .6) 0%, rgba(0, 255, 0, 0) 55%);
}

.game-log-table {
  font-size: xx-small;
  font-weight: 600;
  position: relative;
}

.game-log-row {
  max-height: 300px;
  overflow: auto;
}


.horizontal-scroll {
  width: 100%;
  /* height: 240px; */
  /* width:calc(100% -5px); */
  background-color: #fff;
  white-space: nowrap;

  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}

.sticky-top {
  position: -webkit-sticky;
  z-index: 100;
  top: 0;
  position: sticky;
  background-color: white !important;
}


.hitter-finder-matchup-summary {
  min-width: 300px;
  margin: 5px;
  max-width: 315px;
}

.width-100 {
  width: 100%;
}

img.matchup-summary-pitcher-headshot {
  height: 10%;
  width: auto;
}

.matchup-summary-pitcher-headshot.col {
  max-width: 25%;

}

.relevant {
  background: #eaf2ff;
}

.shrink-weather-game .weather-condition-icon {
  width: 40%;

}

.shrink-weather-game {
  /* transform: scale(60%); */
  font-size: 90%;
}

.weather-table {
  width: 100%;
  table-layout: fixed;
}

.weather-condition-cell {
  justify-items: center;
  align-items: center;
  ;
  justify-content: center;
  text-align: center;
  /* display: flex; */
  flex-direction: column;
  text-wrap: wrap;
}

.weather-condition-icon {
  width: 50px;

}

.weather-condition-icon-small {
  width: 100%;
}

.matchup-summary-weather-game-table {
  border-collapse: collapse;
  border-spacing: 0;
}

.matchup-summary-weather-game-table td,
.matchup-summary-weather-game-table th {
  padding: 0px;
}

.chart-legend.not-showing {
  opacity: 40%;
}

.chart-legend-row {
  /* width: 70%; */
}

.trend-chart {
  margin-left: -6%;
  min-width: 335px;
  height: 225px;
  width: 100%;
}

.trend-charts-row {
  margin: 0px;
  padding: 5px;
}

.chart-legend-fa {
  font-size: 70%;
}

.trend-chart-title {
  font-size: 90%;
  font-weight: bold;
}

.trend-chart-border {
  border-style: solid;
  border-color: lightgray;
  border-width: 1px;
}

.trend-chart-handedness-label-white {
  color: white;
  fill: white;
}

.trend-chart-handedness-label-black {
  color: black;
  fill: black;
}

.trends-view-title {
  margin-top: 5px;
  margin-bottom: -5px;
}

.player-name-search-bar {
  margin-bottom: 10px;
}

.pull-right {
  float: right;
}

table.recent-games {
  /* table-layout: fixed; */
  width: auto;
  min-width: 100%;
}

.navbar-toggler {

  border: 2px solid #fff;
  /* Add a border to make it stand out */
  border-radius: 4px;
  /* Optional: Add some rounding to the corners */
  background-color: #3c4049;
}


#firebaseui-auth-container {
  .firebaseui-info-bar {
    margin-top: 40px;
  }

  .mdl-shadow--2dp {
    box-shadow: none;
  }

  .mdl-progress {
    height: 5px;
  }

  div.mdl-progress::after {
    color: black;
    content: 'Authenticating';
    display: block;
    margin: 20px auto;
    text-align: center;
  }
}



.add-drop-modal .modal-dialog {
  min-width: 350px;
  max-width: 100%;
}

.add-drop-modal .modal-content {
  width: auto;
  margin: auto;
}

.roster-dropdown-scrollable {
  max-height: 350px;
  overflow-y: auto;
}

.spacer-25 {
  min-height: 25px;
}
.spacer-15 {
  min-height: 15px;
}

.player-add-btn {
  margin-top: -5px;
  margin-bottom: 5px;
  font-size: xx-small;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.ranks-raw-radio-btn.active {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

.ranks-raw-radio-btn {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}

.chart-title-one-off {
  margin-bottom: -10px;
  z-index: 10;
}


.league-research-league-stat-rankings {
  min-width: 350px;
  max-width: 385px;
  margin: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.season-ranks {
  padding: 3px;
  border: 1px solid #dddddd;
}


.season-rank-subset-btn-group.row.sticky-top {
  top: 40px;
  background-color: transparent !important;
}

.season-rank-subset-btn-group {
  background-color: white;
  ;
}

.stat-pie-chart {
  padding-left: 10px;
}

.active-stat {
  /* border-style: solid;
  border-color: lightblue;
  border-width: 1px; */
  background: radial-gradient(circle, rgba(204, 238, 255, .5) 0%, rgba(0, 255, 0, 0) 80%);
}

th.active-stat {
  text-decoration: underline;
  /* color: blue; */
  font-size: 110%;
}

.league-leader-col {
  min-width: 120px;
  /* max-width: 50%; */
  margin: 5px;
}


.soft-border-left {
  border-left: 1px solid lightgray;
}

.lucky-col {
  min-width: 350px;
  
}

.weekly-summary-standout {
  border-radius: 8px;
  margin: 5px;
  background-color: #3c4049;
  padding: 3px;
}

.standings-shakeup.riser {
  margin-right: 2.5px;
  border-color: lightgreen;
}

.standings-shakeup.faller {
  background-color: #3c4049;
  
  margin-left: 2.5px;
}

.weekly-summary {
  background-color: #282c35;
  color: white;
  font-family: roboto;
}

.weekly-summary.empty {
  height: 100%;
  min-height: 800px;
}

.weekly-summary-table {
  color: white;
  font-family: roboto;
}

.league-leader-value {
  margin-bottom: -3px;
}

.season-stat-leader {
  min-width: 250px;
}

.font-weight-500.sm {
  font-weight: 1000;
}

.good-label {
  color: #90EE90;
}

.bad-label {
  color: #FF6347;
}


.record-book-timeframe-btn {
 
  min-width: 90px;
}

.record-book-stat-btn {
  font-size: 80%;
  min-width: 60px;
}

.timeframe-selector {
  margin-bottom: 10px;
}

.record-book-table {
  font-size: 80%;
}

.powered-by-stripe {
  width: 200px;
}

.subscription-img {
  width: 90px;
  border-radius: 8px;
}

.account-management {
  padding: 10px;
}

.plan-card {
  border: 1px solid #dddddd;
  border-radius: 8px;
  padding: 10px;
  margin: 5px;
}

.plan-card.current {
  border-color: #007bff;
  background-color: lightblue;
}

.card-element-container {
  border: 1px solid #dddddd;
  border-radius: 8px;
  padding: 10px;
  margin: 5px;
}

.app-img-carousel {
  width: 40%;
  border-radius: 5px;
}

.landing-page-carousel {
  background-color: transparent;
  margin-bottom: 150px;
}

.landing-page-carousel-item {
  width: 100%;
  overflow: hidden;
}


.ff-logo-container {
  display: flex;
  justify-content: center;
  width: 125%;
  margin-top: -10px;
  max-width: 800px;  /* adjust this value as needed */
}

.ff-logo {
  width: 100%;
  height: auto;
  object-fit: contain;
}


.youtube-clip-iframe {
  
  border-radius: 20px;
  overflow: hidden;
}

.sales-pitch {
  margin-top: 20px;
}

.benefits-container {
  width: 100%;
  margin: 1rem 0;
  padding-left: 20%;
}

.benefit-item {
  display: flex;
  align-items: center;
}

.connectable-teams-table {
  max-width: 750px;
}


.player-search-filter.row {
  margin: 0px;
}

.search-filter-dropdown {
  min-width: 100px;
}


.projected-starters-container {
  /* justify-content: center;  
  text-align: center; */
  justify-items: center;
}


.projected-starters-body {
  max-width: 1400px;
  width: 100%;
}


.roster-researcher-container {
  justify-items: center;
}


.roster-researcher-body {
  max-width: 1000px;
  width: 100%;
}

.strategy-builder {
  justify-items: center;
}

.strategy-builder-body {
  max-width: 1000px;
  width: 100%;
}



.light-gray-border {
  border: 1px solid #dddddd;
}

.pie-chart-weekly-stat-container {
  justify-items: center;
  padding-left: 10px;
}

.flex-shrink-1 {
  flex-shrink: 1;
}


.game-sweater-container {
  margin-left: 10px;
}

.game-sweater-body {
  /* max-width: 1400px; */
}

.firebaseui-idp-button {
  border-color: pink !important;
  color: pink !important;
  background-color: black  !important;
}

.firebaseui-idp-text {
  
  color: white !important;

}